<template>
  <BackofficeBase :loader_prop="loader_prop">
    <h3>Order Refund</h3><br/>
    Sale Price: {{ payme_order_data.sale_price }}
    <form @submit="backoffice_payme_order_refund_action">
      Amount to refund:<br />
      <input type="text" v-model="amount_to_refund" /><br/>
      <input type="submit" />
    </form>
    <div>{{ refund_res }}</div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficePaymeOrderRefund',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop: true,
      payme_order_data : {},
      amount_to_refund : 0.0,
      refund_res : ''
    }
  },
  async mounted() {
    let backendModel = new BackendModel()
    let payme_order_data_res = await backendModel.backendRequest("/Api/service/backoffice/backoffice_payme_order_refund", {order_id : this.$route.query.id})
    this.payme_order_data = payme_order_data_res.data.data
    let sale_price = this.payme_order_data.sale_price / 100
    this.payme_order_data.sale_price = sale_price
    this.amount_to_refund = sale_price
    this.loader_prop = false
  },
  methods : {
    async backoffice_payme_order_refund_action (e) {
      e.preventDefault()
      this.loader_prop = true
      let backendModel = new BackendModel()
      let refund_res = await backendModel.backendRequest("/Api/service/backoffice/backoffice_payme_order_refund_action", {order_id : this.$route.query.id, amount_to_refund : this.amount_to_refund})
      this.refund_res = refund_res.data.refund_res
      this.loader_prop = false
    }
  }
}
</script>

<style>
.item_action {
  font-size: 10px;
}
</style>
